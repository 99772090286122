import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { format, subDays, parseISO } from 'date-fns';
import formatQuery from 'utils/formatQuery';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';

export type Language = 'pt-BR' | 'pt-PT' | 'en' | 'es';
export type DateType = 'today' | 'last30' | 'last7' | 'last24' | 'personalized';
export type IPost = {
  post_id: string;
  content: string;
  service: 'facebook' | 'instagram' | 'twitter';
  likes?: number;
  rts?: number;
  impressions?: number;
  shares?: number;
  comments_amounts?: number;
  page_id?: string;
  type: string;
  link?: string;
  reactions?: number;
  date: string;
  content_type?: string;
  picture_full_size?: string;
  sentiment?: string;
  engagement?: number;
  url?: string;
  user_login?: string;
  author: {
    name?: string;
    login?: string;
    profile?: string;
    profile_image_url?: string;
    followers?: number;
    tweets?: number;
    screenname?: string;
  };
};

export type Params = {
  obligatory: string;
  optional: string;
  banned: string;
  languages: Language[];
  date: {
    type: DateType;
    start: string;
    end: string;
  };
};

export type Metric = {
  label: string;
  value: number;
};
type Results = {
  term_evolution?: Array<Metric>;
  sentiments?: Array<Metric>;
  services?: Array<Metric>;
  genders?: Array<Metric>;
  posts: {
    facebook?: Array<IPost>;
    instagram?: Array<IPost>;
    twitter?: Array<IPost>;
  };
};

export const allServices = [
  'twitter',
  'facebook',
  'instagram',
  'photos',
  'videos',
  'forums',
  'blogs',
  'news',
  'miscellaneous',
];
export interface QueryBuilderData {
  service?: string[];
  grouping?: string;
  size?: number;
  sort_by?: string;
  customParams?: Params;
}

interface QueryBuilderResponse {
  authentication_params: {
    bm_user: string;
    api_key: string;
  };
  general_params: {
    since: string;
    until: string;
    language: string[];
    timezone: string;
    service?: string[];
    query: string;
  };
  sort_by?: string;
  size?: number;
  grouping?: string;
}

interface SearchContextData {
  params: Params;
  setParams: React.Dispatch<React.SetStateAction<Params>>;
  clearParams(): void;
  booleanMode: boolean;
  handleObligatoryChange(value: string): void;
  preview: string;
  queryBuilder(data: QueryBuilderData): QueryBuilderResponse;
  addNewTermToSearch(term: string): void;
  searchedTerms: Params[];
  setSearchedTerms: React.Dispatch<React.SetStateAction<Params[]>>;
  resetParams(): void;
  removeTermFromSearch(term: string): void;
  editTermToSearch(oldTerm: string, newTerm: string): void;
  comparisonTerms: Params[];
  setComparisonTerms: React.Dispatch<React.SetStateAction<Params[]>>;
}

const initialParams = {
  obligatory: '',
  optional: '',
  banned: '',
  languages: [],
  date: {
    type: 'last30',
    start: format(subDays(new Date(), 30), 'yyyy-MM-dd hh:mm:ss').toString(),
    end: format(new Date(), 'yyyy-MM-dd hh:mm:ss').toString(),
  },
} as Params;

const SearchContext = createContext<SearchContextData>({} as SearchContextData);

const SearchProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [params, setParams] = useState<Params>({ ...initialParams });
  const [searchedTerms, setSearchedTerms] = useState<Params[]>([]);
  const [booleanMode, setBooleanMode] = useState(false);
  const [preview, setPreview] = useState('');
  const [comparisonTerms, setComparisonTerms] = useState<Params[]>([]);

  const termExists = useCallback(
    (termToCheck: string): boolean => {
      return (
        searchedTerms.findIndex(term => term.obligatory === termToCheck) > -1
      );
    },
    [searchedTerms],
  );

  const addNewTermToSearch = useCallback(
    (term: string) => {
      if (termExists(term)) {
        return;
      }
      setParams({ ...params, obligatory: term, optional: '', banned: '' });
      setSearchedTerms(prevTerms => [
        ...prevTerms,
        { ...params, obligatory: term, optional: '', banned: '' },
      ]);
    },
    [params, termExists],
  );

  const removeTermFromSearch = useCallback((term: string) => {
    setSearchedTerms(prevTerms =>
      prevTerms.filter(termObj => termObj.obligatory !== term),
    );
  }, []);

  const editTermToSearch = useCallback(
    (oldTerm: string, newTerm: string) => {
      if (oldTerm === newTerm || termExists(newTerm)) {
        return;
      }
      const index = searchedTerms.findIndex(
        term => term.obligatory === oldTerm,
      );

      const newParam = {
        ...params,
        obligatory: newTerm,
        optional: '',
        banned: '',
      };

      const newSearchedTerms = Array.from(searchedTerms);
      newSearchedTerms.splice(index, 1, newParam);

      setSearchedTerms(newSearchedTerms);
      setParams(newParam);
    },
    [params, searchedTerms, termExists],
  );

  const clearParams = useCallback(() => {
    setParams({ ...initialParams });
  }, []);

  const resetParams = useCallback(() => {
    setParams(searchedTerms[0]);
  }, [searchedTerms]);

  useEffect(
    () =>
      setPreview(
        formatQuery({
          obligatory: params.obligatory,
          optional: params.optional,
          banned: params.banned,
        }),
      ),
    [params.obligatory, params.optional, params.banned],
  );

  const handleObligatoryChange = useCallback(
    (value: string) => {
      setParams({ ...params, obligatory: value });
      const hasBooleanSearch = !!value.match(/(")|(OR)|(AND)|(NOT)/g);
      if (hasBooleanSearch) {
        if (!booleanMode) setBooleanMode(true);
      } else if (booleanMode) setBooleanMode(false);
    },
    [booleanMode, params],
  );

  const queryBuilder = useCallback(
    ({
      service,
      grouping,
      size,
      sort_by,
      customParams = params,
    }: QueryBuilderData): QueryBuilderResponse => ({
      authentication_params: {
        bm_user: user.username || 'guest',
        api_key:
          user.public_api_key ||
          'MfcySfJ4jw8Wj3bSlxExD4UEnpDoiAfVDfhjlRdsdafk1g2cDw',
      },
      general_params: {
        since: format(
          parseISO(customParams.date.start),
          'yyyyMMddHHmmss',
        ).toString(),
        until: format(
          parseISO(customParams.date.end),
          'yyyyMMddHHmmss',
        ).toString(),
        language: customParams.languages || [],
        timezone: user.time_zone || '-3.0',
        service: service ? [...service] : undefined,
        query: customParams.obligatory,
      },
      sort_by,
      size,
      grouping,
    }),
    [params, user],
  );

  /*
    Updates the page URL when the searchedTerms are changed by adding, editing 
    or deleting.
  */
  useEffect(() => {
    if (searchedTerms.length >= 1) {
      const { date } = searchedTerms[0];

      const searchedTerm = {
        obligatory: searchedTerms[0].obligatory,
        optional: searchedTerms[0].optional,
        banned: searchedTerms[0].banned,
        languages: JSON.stringify(searchedTerms[0].languages),
        dateType: String(date.type),
        dateRange: JSON.stringify([date.start, date.end]),
        compare: JSON.stringify(searchedTerms.slice(1).map(x => x.obligatory)),
      };

      const urlParams = new URLSearchParams(searchedTerm).toString();
      history.push(`/results?${urlParams}`);
    }
  }, [history, searchedTerms]);

  return (
    <SearchContext.Provider
      value={{
        params,
        setParams,
        clearParams,
        handleObligatoryChange,
        booleanMode,
        preview,
        queryBuilder,
        addNewTermToSearch,
        searchedTerms,
        setSearchedTerms,
        resetParams,
        removeTermFromSearch,
        editTermToSearch,
        comparisonTerms,
        setComparisonTerms,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch(): SearchContextData {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider!');
  }

  return context;
}

export { SearchProvider, useSearch };
